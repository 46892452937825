<div class="pt-5">
    <div class="col-md-5 mx-auto card">
        <div class="card-header">
            <h2>Log In</h2>
        </div>
        <div class="card-body">
          <form class="form mt-4" role="form" (ngSubmit)="onSubmit()">
            <!-- Email input -->
            <div class="form-outline mb-4">
              <input type="email" id="form2Example1" class="form-control" name="authEmail" [(ngModel)]="email" />
              <label class="form-label" for="form2Example1">Email address</label>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-4">
              <input type="password" id="form2Example2" class="form-control" name="authPassword" [(ngModel)]="password"/>
              <label class="form-label" for="form2Example2">Password</label>
            </div>

            <!-- 2 column grid layout for inline styling -->
            <!-- <div class="row mb-4">
              <div class="col d-flex justify-content-center">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="form2Example31" />
                  <label class="form-check-label" for="form2Example31"> Remember me </label>
                </div>
              </div>

              <div class="col">
                <a href="#!">Forgot password?</a>
              </div>
            </div> -->
            <p class="text-danger small">{{authService['message']}}</p>
            <!-- Submit button -->
            <button type="submit" class="btn btn-primary btn-block mb-4">Sign in</button>

          </form>
        </div>
    </div>
</div>
