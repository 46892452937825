<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <legend>User List</legend>
    <div class="input-group mb-3">
        <input [disabled]="disabled" type="text" class="form-control form-control" placeholder="Search by email or user name" name="search" [(ngModel)]="searchVal">
        <button [disabled]="disabled" type="button" class="input-group-text btn-primary" (click)="list()"><i class="fa-solid fa-magnifying-glass"></i></button>
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>User Name</th>
                <th>Email</th>
                <th>Active Status</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listInfo">
                <tr (dblclick)="select(item)" (click)="select(item)" role="button" class="link">
                    <td>{{item['userName']}}</td>
                    <td>{{item['email']}}</td>
                    <td>{{ ((item['active']) ? 'Active' : 'Disabled')}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(page-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="list(page)" (focusout)="list(page)" min="1" max="{{pages}}" class="page-link" type="number" name="pagenum" [(ngModel)]="page"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pages}}</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(page+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(pages)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="list()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <button [disabled]="disabled" class="btn btn-primary" type="button" (click)="new()">Create New</button>
</div>
<div id="userForm" class="container" *ngIf="state == 'view'">
    <legend>User</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Email Address</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.email}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">User Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.userName}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label d-block">Permissions</label>
        </div>
        <div class="col-9">
            <div class="form-check">
                <i class="form-check-input fa-solid" [ngClass]="(checked('ROLE_ADMIN')? 'fa-square-check' : 'fa-square')"id="optionA" ></i>
                <label class="form-check-label" for="optionA">Administrator</label>
            </div>
            <div class="form-check">
                <i class="form-check-input fa-solid" [ngClass]="(checked('ROLE_CASH')? 'fa-square-check' : 'fa-square')"id="optionA" ></i>
                <label class="form-check-label" for="optionB">Casher</label>
            </div>
            <div class="form-check">
                <i class="form-check-input fa-solid" [ngClass]="(checked('ROLE_SUP')? 'fa-square-check' : 'fa-square')"id="optionA" ></i>
                <label class="form-check-label" for="optionC">Supervisor</label>
            </div>
            <div class="form-check">
                <i class="form-check-input fa-solid" [ngClass]="(checked('ROLE_CUS')? 'fa-square-check' : 'fa-square')"id="optionA" ></i>
                <label class="form-check-label" for="optionC">Member Service Rep</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Status</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{ ((selected.active) ? 'Active' : 'Disabled')}}</div>
        </div>
    </div>
    <div class="row" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary" (click)="back()">Back</button>
        <button [disabled]="disabled" class="btn btn-primary" id="submitButton" type="button" (click)="edit()"><i class="fa-solid fa-pen-to-square"></i> Edit</button>
    </div>

</div>
<form id="userForm" class="container" *ngIf="state=='edit'">
    <legend>User Edit</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Email Address</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="emailAddress" name="userEmail" type="email" [(ngModel)]="editSelected.email" placeholder="Email Address" required />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">User Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="userName" name="userName" type="text" [(ngModel)]="editSelected.userName" required />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label d-block">Permissions</label>
        </div>
        <div class="col-9">
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionA" type="checkbox" name="newField12" (change)="checkValue($event, 'ROLE_ADMIN')" [checked]="checked('ROLE_ADMIN', editSelected)"/>
                <label class="form-check-label" for="optionA">Administrator</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionB" type="checkbox" name="newField12" (change)="checkValue($event, 'ROLE_CASH')" [checked]="checked('ROLE_CASH', editSelected)"/>
                <label class="form-check-label" for="optionB">Casher</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionC" type="checkbox" name="newField12" (change)="checkValue($event, 'ROLE_SUP')" [checked]="checked('ROLE_SUP', editSelected)"/>
                <label class="form-check-label" for="optionC">Supervisor</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionD" type="checkbox" name="newField12" (change)="checkValue($event, 'ROLE_CUS')" [checked]="checked('ROLE_CUS', editSelected)"/>
                <label class="form-check-label" for="optionC">Member Service Rep</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Active</label>
        </div>
        <div class="col-9">
            <div class="form-check form-switch"><input name="userActive" [disabled]="disabled" class="form-check-input" type="checkbox" [(ngModel)]="editSelected.active"/></div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">New Password</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="newPass" name="newPass" type="password" [(ngModel)]="editSelected.newPassword" required />
            <button [disabled]="disabled" type="button" class="btn btn-primary pr-1" (click)="updatePassword()">Update Password</button>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="select()">cancel</button>
        <button [disabled]="disabled" class="btn btn-primary" id="submitButton" type="button" (click)="update()"><i class="fa-solid fa-floppy-disk"></i> Save</button>
    </div>

</form>
<form id="userForm" class="container" *ngIf="state == 'create'" (ngSubmit)="create()">
    <legend>User Registration</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Email Address</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="emailAddress" name="userEmail" type="email" [(ngModel)]="editSelected.email" placeholder="Email Address" required />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">User Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="userName" name="userName" type="text" [(ngModel)]="editSelected.userName" required />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label d-block">Permissions</label>
        </div>
        <div class="col-9">
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionA" type="checkbox" name="newField12"  (change)="checkValue($event, 'ROLE_ADMIN')" [checked]="checked('ROLE_ADMIN', editSelected)"/>
                <label class="form-check-label" for="optionA">Administrator</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionB" type="checkbox" name="newField12"  (change)="checkValue($event, 'ROLE_CASH')" [checked]="checked('ROLE_CASH', editSelected)"/>
                <label class="form-check-label" for="optionB">Casher</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionC" type="checkbox" name="newField12"  (change)="checkValue($event, 'ROLE_SUP')" [checked]="checked('ROLE_SUP', editSelected)"/>
                <label class="form-check-label" for="optionC">Supervisor</label>
            </div>
            <div class="form-check">
                <input [disabled]="disabled" class="form-check-input" id="optionD" type="checkbox" name="newField12" (change)="checkValue($event, 'ROLE_CUS')" [checked]="checked('ROLE_CUS', editSelected)"/>
                <label class="form-check-label" for="optionC">Member Service Rep</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Active</label>
        </div>
        <div class="col-9">
            <div class="form-check form-switch"><input name="userActive" [disabled]="disabled" class="form-check-input" type="checkbox" [(ngModel)]="editSelected.active"/></div>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" class="btn btn-secondary" type="button" (click)="back()">Cancel</button>
        <button [disabled]="disabled" class="btn btn-primary" type="submit">Submit</button>
    </div>
</form>
