<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <legend>Select Member For Adjustment</legend>
    <div class="input-group mb-3">
        <input [disabled]="disabled" type="text" class="form-control form-control" placeholder="Search by First Name, Last Name or Book Number" name="search" [(ngModel)]="searchVal">
        <button [disabled]="disabled" type="button" class="input-group-text btn-primary" (click)="list()"><i class="fa-solid fa-magnifying-glass"></i></button>
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Book Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listInfo">
                <tr (dblclick)="select(item)" (click)="select(item)" role="button" class="link">
                    <td>{{item['book_number']}}</td>
                    <td>{{item['fname']}}</td>
                    <td>{{item['lname']}}</td>
                    <td>{{dateToString(item['dob'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(page-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="list(page)" (focusout)="list(page)" min="1" max="{{pages}}" class="page-link" type="number" name="pagenum" [(ngModel)]="page"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pages}}</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(page+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(pages)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="list()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <div class="d-flex gap-3 pt-3">
        <button [disabled]="disabled" *ngIf="selected" class="btn btn-primary pr-1" type="button" (click)="newTransaction()">Create Adjustment for {{selected['fname']+' '+selected['lname'] }}</button>
        <button [disabled]="disabled" *ngIf="selected" class="btn btn-primary" type="button" (click)="viewTransactions()">View Transaction History for {{selected['fname']+' '+selected['lname'] }}</button>
    </div>

</div>
<form id="userForm" class="container" *ngIf="state == 'create'" (ngSubmit)="create()">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <legend>Adjustment - {{selected['fname']+' '+selected['lname'] }}</legend>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <legend>Transactions</legend>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-item [ngForOf]="transaction">
                                    <tr>
                                        <td>{{item['type']}}</td>
                                        <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                                        <td><button type="button" name="button" class="btn btn-primary" (click)="deleteTransaction(item)"><i class="fa-solid fa-minus"></i></button></td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <!-- <div class="col-md-12 text-right clearfix">
                            <button type="button" name="button" class="btn btn-primary float-end" (click)="addTransaction(transaction)"><i class="fa-solid fa-square-plus"></i></button>
                        </div> -->
                        <div class="col-md-12 pt-3">
                            <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && create()">
                                <div class="row mb-1">
                                    <div class="col-3">
                                        <label class="form-label">Type</label>
                                    </div>
                                    <div class="col-9">
                                        <select [disabled]="disabled" name="tType" id="tType" class="form-select form-control" [(ngModel)]="tType" required>
                                            <option value="Increase">Increase</option>
                                            <option value="Decrease">Decrease</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-3">
                                        <label class="form-label">Amount</label>
                                    </div>
                                    <div class="col-9">
                                        <input [disabled]="disabled" class="form-control" id="tAmount" name="tAmount" type="number" min="0.01" [(ngModel)]="tAmount" required/>
                                    </div>
                                </div>
                                <div class="col-md-12 text-right clearfix pb-3">
                                    <button class="btn btn-primary float-end" [disabled]="disabled || !f.form.valid" type="button" (click)="addTransaction()">Add Adjustment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <th scope="row">Starting Balance</th>
                                <td>{{'$'+numberToDecimal(selected['balance'])}}</td>
                            </tr>
                            <tr>
                                <th scope="row">Adjustment Total</th>
                                <td>{{'$'+numberToDecimal(tSubTotal)}}</td>
                            </tr>
                            <tr>
                                <th scope="row">New Balance</th>
                                <td>{{'$'+numberToDecimal(tNewBalance)}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3 pt-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="cancelTransaction()">cancel</button>
        <button [disabled]="disabled || transaction.length == 0" class="btn btn-primary" id="submitButton" type="button" (click)="create()"><i class="fa-solid fa-floppy-disk"></i> Save</button>
    </div>

</form>
<div class="" *ngIf="state == 'view'">
    <legend>{{selected['fname']+' '+selected['lname'] }} Transaction History</legend>
    <div class="p-3">
        Current Balance : {{'$'+numberToDecimal(selected['balance'])}}
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listTrans">
                <tr>
                    <td>{{item['type']}}</td>
                    <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                    <td>{{dateToString(item['dateCreated'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || pageTrans == 1"><a role="button" class="page-link" (click)="listTransaction(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || pageTrans == 1"><a role="button" class="page-link" (click)="listTransaction(pageTrans-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="listTransaction(pageTrans)" (focusout)="listTransaction(pageTrans)" min="1" max="{{pagesTrans}}" class="page-link" type="number" name="pagenum" [(ngModel)]="pageTrans"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pagesTrans}}</span></li>
        <li class="page-item" [class.disabled]="disabled || pageTrans == pagesTrans"><a role="button" class="page-link" (click)="listTransaction(pageTrans+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || pageTrans == pagesTrans"><a role="button" class="page-link" (click)="listTransaction(pagesTrans)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || pageTrans == pagesTrans">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="listTransaction()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="cancelTransaction()">Back</button>
</div>
