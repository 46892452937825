<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <legend>Select Member For Death Benefit</legend>
    <div class="input-group mb-3">
        <input [disabled]="disabled" type="text" class="form-control form-control" placeholder="Search by First Name, Last Name or Book Number" name="search" [(ngModel)]="searchVal">
        <button [disabled]="disabled" type="button" class="input-group-text btn-primary" (click)="list()"><i class="fa-solid fa-magnifying-glass"></i></button>
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Book Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listInfo">
                <tr (dblclick)="select(item)" (click)="select(item)" role="button" class="link">
                    <td>{{item['book_number']}}</td>
                    <td>{{item['fname']}}</td>
                    <td>{{item['lname']}}</td>
                    <td>{{dateToString(item['dob'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(page-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="list(page)" (focusout)="list(page)" min="1" max="{{pages}}" class="page-link" type="number" name="pagenum" [(ngModel)]="page"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pages}}</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(page+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(pages)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="list()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <div class="d-flex gap-3 pt-3">
        <button [disabled]="disabled" *ngIf="selected" class="btn btn-primary pr-1" type="button" (click)="newBenefit()">Create Death Benefit for {{selected['fname']+' '+selected['lname'] }}</button>
    </div>

</div>
<form id="userForm" name="form" #f="ngForm" class="container" *ngIf="state == 'create'" (ngSubmit)="f.form.valid && create()">
    <legend>Death Benefit</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Death</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-calendar"></i></span>
                <input class="form-control" uiDatePicker id="dateOfDeathBenefit" type="text" required name="memberDOD" #memberDOD="ngModel" [(ngModel)]="selected.dateDeath" />
            </div>
            <div *ngIf="memberDOD.invalid && (memberDOD.dirty || memberDOD.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberDOD.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Book Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected['book_number']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Title</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.title}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">First Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.fname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Middle Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.mname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Last Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.lname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Birth</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{dateToString(selected.dob)}}</div>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" class="btn btn-secondary" type="button" (click)="cancelTransaction()">Cancel</button>
        <button [disabled]="disabled || !f.form.valid" class="btn btn-primary" type="submit">Submit</button>
    </div>
</form>
<div id="userForm" class="container" *ngIf="state == 'view'">
    <legend>Member</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Application Date</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.date_of_application}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Branch</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.branch['name']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Book Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected['book_number']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Title</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.title}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">First Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.fname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Middle Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.mname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Last Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.lname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Birth</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.dob}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Death</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.dateDeath}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Closure</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.dateClosed}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Address</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.address}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Contact Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.contact_number}}</div>
        </div>
    </div>
    <div class="row">
        <legend>Beneficiaries</legend>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="selected.beneficiary">
                    <tr>
                        <td>{{item['name']}}</td>
                        <td>{{item['relationship']}}</td>
                        <td>{{item['address']}}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="row" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary" (click)="cancelTransaction()">Back</button>
    </div>

</div>
