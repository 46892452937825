<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'view'">
    <legend>Current Batch</legend>
    <div class="p-3">
        <span>Current Total</span> : {{'$'+numberToDecimal(totalBatch(selected['transaction']))}}
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Book Number</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="selected['transaction']">
                <tr>
                    <td>{{item['type']}}</td>
                    <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                    <td>{{dateToString(item['dateCreated'])}}</td>
                    <td>{{item['insured']['book_number']}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="refresh()">Refresh</button>
    </div>
</div>

<div class="row pt-3" id="submitMessage" *ngIf="state == 'error'">
    <div class=" text-center mb-3 fw-bold">{{message}}</div>
</div>
