<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <legend>Member List</legend>
    <div class="input-group mb-3">
        <input [disabled]="disabled" type="text" class="form-control form-control" placeholder="Search by First Name, Last Name or Book Number" name="search" [(ngModel)]="searchVal">
        <button [disabled]="disabled" type="button" class="input-group-text btn-primary" (click)="list()"><i class="fa-solid fa-magnifying-glass"></i></button>
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Book Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listInfo">
                <tr (dblclick)="select(item)" (click)="select(item)" role="button" class="link">
                    <td>{{item['book_number']}}</td>
                    <td>{{item['fname']}}</td>
                    <td>{{item['lname']}}</td>
                    <td>{{dateToString(item['dob'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(page-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="list(page)" (focusout)="list(page)" min="1" max="{{pages}}" class="page-link" type="number" name="pagenum" [(ngModel)]="page"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pages}}</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(page+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(pages)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="list()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <button [disabled]="disabled" class="btn btn-primary" type="button" (click)="new()" *ngIf="authService.hasPermission(['ROLE_CUS'])">Create New</button>
</div>
<div id="userForm" class="container" *ngIf="state == 'view'">
    <legend>Member</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Application Date</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.date_of_application}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Branch</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.branch['name']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Book Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected['book_number']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Title</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.title}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">First Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.fname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Middle Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.mname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Last Name</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.lname}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Birth</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.dob}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Closure</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.dateClosed}}</div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="selected.dateDeath">
        <div class="col-3">
            <label class="form-label">Date of Death</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{displayDate(selected.dateDeath)}}</div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Address</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.address}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Contact Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.contact_number}}</div>
        </div>
    </div>
    <div class="row">
        <legend>Beneficiaries</legend>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="selected.beneficiary">
                    <tr>
                        <td>{{item['name']}}</td>
                        <td>{{item['relationship']}}</td>
                        <td>{{item['address']}}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="row">
        <legend>Images</legend>
        <div class="accordion" id="accordion">
            <ng-template ngFor let-item [ngForOf]="selected.image">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" (click)="loadImage(item)" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + item['id']" aria-expanded="false" [attr.aria-controls]="'collapse' + item['id']">
                            {{item['type']}}

                        </button>
                    </h2>
                    <div id="collapse{{item['id']}}" class="accordion-collapse collapse" aria-labelledby="headingOne">
                        <div *ngIf="item['image']" class="accordion-body" style="height: 600px;">
                            <!--<img *ngIf="item['image']" class="img-fluid" [src]="item['image'] | safe:'url'">-->
                            <iframe *ngIf="item['image']" [src]="item['image'] | safe:'resourceUrl'" style="width: 100%;height: 100%;border: none;"></iframe>
                        </div>
                        <div *ngIf="!item['image']" class="accordion-body">
                            Loading......
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="row" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary" (click)="back()">Back</button>
        <button [disabled]="disabled" class="btn btn-primary" id="submitButton" type="button" (click)="edit()" *ngIf="authService.hasPermission(['ROLE_CUS'])"><i class="fa-solid fa-pen-to-square"></i> Edit</button>
    </div>

</div>
<form id="userForm" name="form" #f="ngForm" class="container" *ngIf="state=='edit'" (ngSubmit)="f.form.valid && update()">
    <legend>Member Edit</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Application Date</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.date_of_application}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Branch</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected.branch['name']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Book Number</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{selected['book_number']}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Title</label>
        </div>
        <div class="col-9">
            <select [disabled]="disabled" name="insuredTitle" #insuredTitle="ngModel" class="form-select form-control" [(ngModel)]="editSelected.title" required>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
            </select>
            <div *ngIf="insuredTitle.invalid && (insuredTitle.dirty || insuredTitle.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="insuredTitle.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">First Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberFirstName" name="memberFirstName" #memberFirstName="ngModel" type="text" [(ngModel)]="editSelected.fname" required />
            <div *ngIf="memberFirstName.invalid && (memberFirstName.dirty || memberFirstName.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberFirstName.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Middle Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberMiddleName" name="memberMiddleName" #memberMiddleName="ngModel" type="text" [(ngModel)]="editSelected.mname" />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Last Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberLastName" name="memberLastName" #memberLastName="ngModel" type="text" [(ngModel)]="editSelected.lname" required />
            <div *ngIf="memberLastName.invalid && (memberLastName.dirty || memberLastName.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberLastName.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Birth</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-calendar"></i></span>
                <input class="form-control" uiDatePicker id="dateOfBirth" type="text" name="memberDOB" #memberDOB="ngModel" [(ngModel)]="editSelected.dob" required/>
            </div>
            <div *ngIf="memberDOB.invalid && (memberDOB.dirty || memberDOB.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberDOB.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Closure</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-calendar"></i></span>
                <input class="form-control" uiDatePicker id="dateOfColsure" type="text" name="memberDOC" #memberDOC="ngModel" [(ngModel)]="editSelected.dateClosed"/>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="selected.dateDeath">
        <div class="col-3">
            <label class="form-label">Date of Death</label>
        </div>
        <div class="col-9">
            <div class="form-label">{{displayDate(selected.dateDeath)}}</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Address</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberAddress" name="memberAddress" #memberAddress="ngModel" type="text" [(ngModel)]="editSelected.address" required />
            <div *ngIf="memberAddress.invalid && (memberAddress.dirty || memberAddress.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberAddress.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Contact Number</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberContact" name="memberContact" #memberContact="ngModel" maxlength="5" minlength="25" type="text" [(ngModel)]="editSelected.contact_number" required />
            <div *ngIf="memberContact.invalid && (memberContact.dirty || memberContact.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberContact.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <legend>Beneficiaries</legend>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Address</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="editSelected.beneficiary">
                    <tr>
                        <td>{{item['name']}}</td>
                        <td>{{item['relationship']}}</td>
                        <td>{{item['address']}}</td>
                        <td><button type="button" name="button" class="btn btn-primary" (click)="deleteBeneficiary(item)"><i class="fa-solid fa-minus"></i></button></td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-md-12 pt-3">
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Name</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bName" name="bName" type="text" [(ngModel)]="bName" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Relationship</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bRelationship" name="bRelationship" type="text" [(ngModel)]="bRelationship" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Address</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bAddress" name="bAddress" type="text" [(ngModel)]="bAddress" />
            </div>
        </div>
        <div class="col-md-12 text-right clearfix pb-3">
            <button class="btn btn-primary float-end" [disabled]="disabled" type="button" (click)="addBeneficiary()">Add Beneficiary</button>
        </div>

    </div>
    <!--<div class="row">
        <legend>Beneficiaries</legend>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="selected.beneficiary">
                    <tr>
                        <td><input [disabled]="disabled" class="form-control" id="ben{{item['name']}}" name="ben{{item['name']}}" type="text" [(ngModel)]="item['name']" /></td>
                        <td><input [disabled]="disabled" class="form-control" id="ben{{item['relationship']}}" name="ben{{item['relationship']}}" type="text" [(ngModel)]="item['relationship']" /></td>
                        <td><input [disabled]="disabled" class="form-control" id="ben{{item['address']}}" name="ben{{item['address']}}" type="text" [(ngModel)]="item['address']" /></td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-md-12 text-right clearfix pb-3">
        <button type="button" name="button" class="btn btn-primary float-end" (click)="addBeneficiary()"><i class="fa-solid fa-square-plus"></i></button>
    </div>-->
    <div class="row pb-2">
        <legend>Images</legend>
        <div class="accordion" id="accordion">
            <ng-template ngFor let-item [ngForOf]="editSelected.image">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" (click)="loadImage(item)" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + item['id']" aria-expanded="false" [attr.aria-controls]="'collapse' + item['id']">
                            {{item['type']}}

                        </button>
                    </h2>
                    <div id="collapse{{item['id']}}" class="accordion-collapse collapse" aria-labelledby="headingOne">
                        <div class="accordion-body">
                            <img *ngIf="item['image']" class="img-fluid" [src]="item['image']">
                            <div *ngIf="!item['image']">
                             Loading......
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-md-12 pt-3">
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Document Type</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="fileName" name="fileName" type="text" [(ngModel)]="fileName" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">File</label>
            </div>
            <div class="col-9">
                <input (change)="onFileChange($event.target)" [disabled]="disabled" class="form-control" id="file" name="file" type="file" />
            </div>
        </div>
        <div class="col-md-12 text-right clearfix pb-3">
            <button class="btn btn-primary float-end" [disabled]="disabled||!fileName||!fileToUpload" type="button" (click)="addImage()">Add Image</button>
        </div>

    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="select()">cancel</button>
        <button [disabled]="disabled || !f.form.valid" class="btn btn-primary" id="submitButton" type="submit"><i class="fa-solid fa-floppy-disk"></i> Save</button>
    </div>

</form>
<form id="userForm" name="form" #f="ngForm" class="container" *ngIf="state == 'create'" (ngSubmit)="f.form.valid && create()">
    <legend>Member Registration</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Application</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-calendar"></i></span>
                <input class="form-control" uiDatePicker id="dateOfApplication" type="text" required name="memberDOA" #memberDOA="ngModel" [(ngModel)]="editSelected.date_of_application" />
            </div>
            <div *ngIf="memberDOA.invalid && (memberDOA.dirty || memberDOA.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberDOA.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Branch</label>
        </div>
        <div class="col-9">
            <select [disabled]="disabled" name="insuredBranch" #insuredBranch="ngModel" class="form-select form-control" [(ngModel)]="editSelected.branch" required>
                <ng-template ngFor let-item [ngForOf]="listBranch">
                    <option [ngValue]="item">{{item['name']}}</option>
                </ng-template>
            </select>
            <div *ngIf="insuredBranch.invalid && (insuredBranch.dirty || insuredBranch.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="insuredBranch.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Book Number</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span *ngIf="editSelected.branch['id'] == 1" class="input-group-text">48</span>
                <span *ngIf="editSelected.branch['id'] == 2" class="input-group-text">28</span>
                <input [disabled]="disabled" class="form-control" id="memberBookNumber" name="memberBookNumber" #memberBookNumber="ngModel" type="text" [(ngModel)]="editSelected.book_number" required />
            </div>
            <div *ngIf="memberBookNumber.invalid && (memberBookNumber.dirty || memberBookNumber.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberBookNumber.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Title</label>
        </div>
        <div class="col-9">
            <select [disabled]="disabled" name="insuredTitle" #insuredTitle="ngModel" class="form-select form-control" [(ngModel)]="editSelected.title" required>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
            </select>
            <div *ngIf="insuredTitle.invalid && (insuredTitle.dirty || insuredTitle.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="insuredTitle.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">First Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberFirstName" name="memberFirstName" #memberFirstName="ngModel" type="text" [(ngModel)]="editSelected.fname" required />
            <div *ngIf="memberFirstName.invalid && (memberFirstName.dirty || memberFirstName.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberFirstName.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Middle Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberMiddleName" name="memberMiddleName" #memberMiddleName="ngModel" type="text" [(ngModel)]="editSelected.mname" />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Last Name</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberLastName" name="memberLastName" #memberLastName="ngModel" type="text" [(ngModel)]="editSelected.lname" required />
            <div *ngIf="memberLastName.invalid && (memberLastName.dirty || memberLastName.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberLastName.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Date of Birth</label>
        </div>
        <div class="col-9">
            <div class="input-group">
                <span class="input-group-text"><i class="fa-solid fa-calendar"></i></span>
                <input class="form-control" uiDatePicker id="dateOfBirth" type="text" required name="memberDOB" #memberDOB="ngModel" [(ngModel)]="editSelected.dob" required/>
            </div>
            <div *ngIf="memberDOB.invalid && (memberDOB.dirty || memberDOB.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberDOB.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Address</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberAddress" name="memberAddress" #memberAddress="ngModel" type="text" [(ngModel)]="editSelected.address" required />
            <div *ngIf="memberAddress.invalid && (memberAddress.dirty || memberAddress.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberAddress.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">Contact Number</label>
        </div>
        <div class="col-9">
            <input [disabled]="disabled" class="form-control" id="memberContact" name="memberContact" #memberContact="ngModel" type="text" [(ngModel)]="editSelected.contact_number" required />
            <div *ngIf="memberContact.invalid && (memberContact.dirty || memberContact.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="memberContact.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <legend>Beneficiaries</legend>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Address</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="editSelected.beneficiary">
                    <tr>
                        <td>{{item['name']}}</td>
                        <td>{{item['relationship']}}</td>
                        <td>{{item['address']}}</td>
                        <td><button type="button" name="button" class="btn btn-primary" (click)="deleteBeneficiary(item, editSelected)"><i class="fa-solid fa-minus"></i></button></td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-md-12 pt-3">
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Name</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bName" name="bName" type="text" [(ngModel)]="bName" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Relationship</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bRelationship" name="bRelationship" type="text" [(ngModel)]="bRelationship" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <label class="form-label">Address</label>
            </div>
            <div class="col-9">
                <input [disabled]="disabled" class="form-control" id="bAddress" name="bAddress" type="text" [(ngModel)]="bAddress" />
            </div>
        </div>
        <div class="col-md-12 text-right clearfix pb-3">
            <button class="btn btn-primary float-end" [disabled]="disabled" type="button" (click)="addBeneficiary(editSelected)">Add Beneficiary</button>
        </div>

    </div>
    <div class="row pb-2">
        <legend>Images</legend>
        <div class="">
            Images can only be added after a member is created.
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" class="btn btn-secondary" type="button" (click)="back()">Cancel</button>
        <button [disabled]="disabled || !f.form.valid" class="btn btn-primary" type="submit">Submit</button>
    </div>
</form>
