import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { UserComponent } from './user/user.component';
import { MemberComponent } from './member/member.component';
import { TransactionComponent } from './transaction/transaction.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { MultiTransactionComponent } from './multi-transaction/multi-transaction.component';
import { ReportsComponent } from './reports/reports.component';
import { DeductionComponent } from './deduction/deduction.component';
import { BatchComponent } from './batch/batch.component';
import { BenefitComponent } from './benefit/benefit.component';
import { BatchPersonalComponent } from './batch-personal/batch-personal.component';
import { AuthGuardService, AuthGuardServiceLoggedOut } from './services/auth-guard.service';

const routes: Routes = [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, canActivate: [AuthGuardServiceLoggedOut] },
      { path: 'landing', component: LandingComponent, canActivate: [AuthGuardService] },
      { path: 'user', component: UserComponent, canActivate: [AuthGuardService] },
      { path: 'member', component: MemberComponent, canActivate: [AuthGuardService] },
      { path: 'transaction', component: TransactionComponent, canActivate: [AuthGuardService] },
      { path: 'adjustment', component: AdjustmentComponent, canActivate: [AuthGuardService] },
      { path: 'multi-trans', component: MultiTransactionComponent, canActivate: [AuthGuardService] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService] },
      { path: 'deduction', component: DeductionComponent, canActivate: [AuthGuardService] },
      { path: 'batch', component: BatchComponent, canActivate: [AuthGuardService] },
      { path: 'batch-personal', component: BatchPersonalComponent, canActivate: [AuthGuardService] },
      { path: 'benefit', component: BenefitComponent, canActivate: [AuthGuardService] }
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
