<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<form id="userForm" class="container" *ngIf="state == 'create'" (ngSubmit)="create()">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <legend>Multi Book Transaction</legend>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <legend>Transactions</legend>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Book Number</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-item [ngForOf]="transaction">
                                    <tr>
                                        <td>{{item['book_number']}}</td>
                                        <td>{{item['name']}}</td>
                                        <td>{{item['type']}}</td>
                                        <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                                        <td><button type="button" name="button" class="btn btn-primary" (click)="deleteTransaction(item)"><i class="fa-solid fa-minus"></i></button></td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <!-- <div class="col-md-12 text-right clearfix">
                            <button type="button" name="button" class="btn btn-primary float-end" (click)="addTransaction(transaction)"><i class="fa-solid fa-square-plus"></i></button>
                        </div> -->
                        <div class="col-md-12 pt-3">
                            <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && create()">
                                <div class="row mb-1">
                                    <div class="col-3">
                                        <label class="form-label">Book Number</label>
                                    </div>
                                    <div class="col-9">
                                        <input [disabled]="disabled" class="form-control" id="tBNumber" name="tBNumber" type="string" [(ngModel)]="tBNumber" required/>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-3">
                                        <label class="form-label">Type</label>
                                    </div>
                                    <div class="col-9">
                                        <select [disabled]="disabled" name="tType" id="tType" class="form-select form-control" [(ngModel)]="tType" required>
                                            <option value="Deposit">Deposit</option>
                                            <option value="Withdraw">Withdraw</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-3">
                                        <label class="form-label">Amount</label>
                                    </div>
                                    <div class="col-9">
                                        <input [disabled]="disabled" class="form-control" id="tAmount" name="tAmount" type="number" min="0.01" [(ngModel)]="tAmount" required/>
                                    </div>
                                </div>
                                <div class="col-md-12 text-right clearfix pb-3">
                                    <button class="btn btn-primary float-end" [disabled]="disabled || !f.form.valid" type="button" (click)="addTransaction()">Add Transaction</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <th scope="row">Transaction Total</th>
                                <td>{{'$'+numberToDecimal(tSubTotal)}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3 pt-3">
        <button [disabled]="disabled" *ngIf="transaction.length != 0" type="button" class="btn btn-secondary pr-1" (click)="newTransaction()">cancel</button>
        <button [disabled]="disabled || transaction.length == 0" class="btn btn-primary" id="submitButton" type="button" (click)="create()"><i class="fa-solid fa-floppy-disk"></i> Save</button>
    </div>

</form>
<div class="" *ngIf="state == 'view'">
    <legend>Transaction Completed</legend>
    <div class="p-3">
        Transaction total : {{'$'+numberToDecimal(tSubTotal)}}
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>Book Number</th>
                <th>Name</th>
                <th>Type</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="transaction">
                <tr>
                    <td>{{item['book_number']}}</td>
                    <td>{{item['name']}}</td>
                    <td>{{item['type']}}</td>
                    <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="newTransaction()">New Transaction</button>
</div>
