import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(public authService: AuthService, public router: Router) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean>{
        let url: string = state.url;
        await this.authService.checkAuthenticated();
        if (!this.authService.isAuthenticated.value) {
            this.authService.redirectUrl = url;
            await this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthGuardServiceLoggedOut implements CanActivate {

    constructor(public authService: AuthService, public router: Router) { }

	async canActivate(
		next: ActivatedRouteSnapshot,
	    state: RouterStateSnapshot): Promise<boolean>{
        await this.authService.checkAuthenticated();
		if (!this.authService.isAuthenticated.value){
			return true;
		} else {
			await this.router.navigate(['/landing']);
			return false;
		}
	}
}
