import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-multi-transaction',
  templateUrl: './multi-transaction.component.html',
  styleUrls: ['./multi-transaction.component.sass']
})
export class MultiTransactionComponent implements OnInit {

        state:string = "create";
        page:number = 1;
        pages:number = 1;
        disabled:boolean = true;
        listInfo:any[] = [];
        selected:any;
        message:string = '';
        itemsLength:number = 10;
        transaction:any;
        searchVal:string = '';


        listTrans:any[] = [];
        pageTrans:number = 1;
        pagesTrans:number = 1;

        tBNumber:string = '';
        tType:string = '';
        tAmount:number = 0;
        tSubTotal:number = 0;
        tNewBalance:number = 0;

        constructor(
            public authService: AuthService,
            private httpService: HTTPService
        ) {
        }


        ngOnInit(): void {
            this.transaction = [];
            this.disabled = false;
        }

        select(item:any = this.selected) {
            this.selected = item;
        }

        dateToString(date:string) {
            return date.substring(0, 10);
        }

        newTransaction() {
            this.transaction = [];
            this.state="create";
            this.tBNumber = '';
            //this.tNewBalance = this.selected['balance'];
            this.tType = '';
            this.tAmount = 0;
            this.tSubTotal = 0;
            this.tNewBalance = 0;
        }

        create() {
            this.message = '';
            this.disabled = true;
            var obj = {
                "transactions": this.transaction
            };
            this.httpService.sendPostRequest('/transaction/create/multi', obj)
                .subscribe(data=> this.onCreateReturn(data));
        }

        onCreateReturn(data:any) {
            if (data.message && data.message == 'successful') {
                this.disabled = false;
                this.state = "view";
                //this.viewTransactions();
            } else if (data.message && data.message == 'failed') {
                this.disabled = false;
                this.message = data.error[0];
            }
        }

        addTransaction() {
            this.message = '';
            this.disabled = true;
            var obj = {
                "transaction": {
                    "book_number" : this.tBNumber
                }
            };
            this.httpService.sendPostRequest('/transaction/multi/find', obj)
                .subscribe(data=> this.onTransactionReturn(data));

        }

        onTransactionReturn(data:any, list = this.transaction) {
            if (data.message && data.message == 'successful') {
                this.disabled = false;
                var amount = this.tAmount * Math.pow(10, 2);
                list.push({
                    'book_number':this.tBNumber,
                    "name": data.data.fname + ' ' + data.data.lname,
                    "type":this.tType,
                    "amount":amount
                });
                if (this.tType == 'Deposit') {
                    this.tSubTotal += amount;
                    //this.tNewBalance += amount;
                } else if (this.tType == 'Withdraw') {
                    this.tSubTotal -= amount;
                    //this.tNewBalance -= amount;
                }
                //this.viewTransactions();
            } else if (data.message && data.message == 'failed') {
                this.disabled = false;
                this.message = data.error[0];
            }
        }

        deleteTransaction(item:any, list = this.transaction) {
            for(let i=0; i<list.length; i++){
                if (list[i] == item) {
                    list.splice(i,1);
                    if (item.type == 'Deposit') {
                        this.tSubTotal -= item.amount;
                        //item.tNewBalance += amount;
                    } else if (item.type == 'Withdraw') {
                        this.tSubTotal += item.amount;
                        //item.tNewBalance -= amount;
                    }
                }
            }
        }


        numberToDecimal(number:number) {
            number /= Math.pow(10, 2);
            return number.toFixed(2);
        }
}
