import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    jwtHelper: JwtHelperService = new JwtHelperService();
    user: any;
    public isAuthenticated = new BehaviorSubject<boolean>(false);
    remeberMe: boolean = false;
    public redirectUrl:string = '';
    public message:string = '';

    public menu = [
        {title: 'Users', icon: '', path:'/user', roles:["ROLE_ADMIN"]},
        {title: 'Members', icon: '', path:'/member', roles:["ROLE_ADMIN", "ROLE_CASH", "ROLE_SUP", "ROLE_CUS"]},
        {title: 'Transation', icon: '', path:'/transaction', roles:["ROLE_CASH"]},
        {title: 'Adjustment', icon: '', path:'/adjustment', roles:["ROLE_SUP"]},
        {title: 'Multi-Book Transation', icon: '', path:'/multi-trans', roles:["ROLE_CASH"]},
        {title: 'Reports', icon: '', path:'/reports', roles:["ROLE_ADMIN", "ROLE_SUP"]},
        {title: 'Deductions', icon: '', path:'/deduction', roles:["ROLE_SUP"]},
        {title: 'Batches', icon: '', path:'/batch', roles:["ROLE_SUP"]},
        {title: 'Personal Batches', icon: '', path:'/batch-personal', roles:["ROLE_CASH"]},
        {title: 'Death Benefit', icon: '', path:'/benefit', roles:["ROLE_SUP"]}
    ]

    constructor(private router: Router) { }

    async checkAuthenticated() {
        if (sessionStorage.getItem("token") && sessionStorage.getItem("token") !== null && !this.jwtHelper.isTokenExpired(sessionStorage.getItem("token")!)) {
            localStorage.setItem("token", sessionStorage.getItem("token")!);
			this.user = this.jwtHelper.decodeToken(localStorage.getItem("token")!);
            this.isAuthenticated.next(true);
        } else {
            this.isAuthenticated.next(false);
            this.user = null;
            localStorage.removeItem('token');
            sessionStorage.clear();
        }
    }

    async login(token:string) {
        sessionStorage.setItem("token", token);
        this.user = this.jwtHelper.decodeToken(token);
        this.isAuthenticated.next(true);
        if (this.remeberMe) {
            localStorage.setItem("token", token);
        }
        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
        } else {
            this.router.navigate(['/landing']);
        }
    }

    async logout(redirect: string) {
        localStorage.removeItem('token');
        sessionStorage.clear();
        this.user = null;
        this.isAuthenticated.next(false);
        this.router.navigate([redirect]);
    }

    public hasPermission( menuItem: any) : boolean {
        for (let index = 0; index < menuItem.length; index++) {
            for (let userRoleIndex = 0; userRoleIndex < this.user.roles.length; userRoleIndex++) {
                if (this.user.roles[userRoleIndex] == menuItem[index]) {
                    return true;
                }
            }
        }
        return false;
    }
}
