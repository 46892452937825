import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.sass']
})
export class AdjustmentComponent implements OnInit {

    state:string = "list";
    page:number = 1;
    pages:number = 1;
    disabled:boolean = true;
    listInfo:any[] = [];
    selected:any;
    message:string = '';
    itemsLength:number = 10;
    transaction:any;
    searchVal:string = '';


    listTrans:any[] = [];
    pageTrans:number = 1;
    pagesTrans:number = 1;

    tType:string = '';
    tAmount:number = 0;
    tSubTotal:number = 0;
    tNewBalance:number = 0;

    constructor(
        public authService: AuthService,
        private httpService: HTTPService
    ) {
    }


    ngOnInit(): void {
        this.list();
    }

    list(page = 1) {
        this.disabled = true;
        this.page = page;
        var obj = {
            "search" : this.searchVal
        }
        this.httpService.sendPostRequest('/insured/search/'+this.page+'/'+this.itemsLength, obj)
            .subscribe(data=> this.onReturn(data));
    }

    onReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.pages = data.pages;
            this.listInfo = data.data;
            this.selected = null;
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    listTransaction(page = 1) {
        this.disabled = true;
        this.pageTrans = page;
        this.httpService.sendPostRequest('/transaction/listby/insured/'+this.selected['id']+'/'+this.pageTrans+'/'+this.itemsLength+'/desc')
            .subscribe(data=> this.onReturnTransactions(data));
    }

    onReturnTransactions(data:any) {
        if (data.message && data.message == 'successful') {
            this.pagesTrans = data.pages;
            this.listTrans = data.data;
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    select(item:any = this.selected) {
        this.selected = item;
    }

    dateToString(date:string) {
        if (date) {
            return date.substring(0, 10);
        } else {
            return "";
        }
    }

    newTransaction() {
        this.transaction = [];
        this.state="create";
        this.tNewBalance = this.selected['balance'];
        this.tType = '';
        this.tSubTotal = 0;
        this.tAmount = 0;
    }

    viewTransactions() {
        this.state="view";
        this.listTrans = [];
        this.listTransaction();
    }

    create() {
        this.disabled = true;
        var obj = {
            "version": this.selected['version'],
            "insuredId": this.selected['id'],
            "transactions": this.transaction
        };
        this.httpService.sendPostRequest('/transaction/create/adjustment', obj)
            .subscribe(data=> this.onCreateReturn(data));
    }

    onCreateReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.selected = data.data;
            //update the user in the list
            for(let i=0; i< this.listInfo.length; i++){
                if (this.listInfo[i].id == this.selected.id) {
                    this.listInfo[i] = data.data;
                }
            }
            this.viewTransactions();
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    addTransaction(list = this.transaction) {
        var amount = this.tAmount * Math.pow(10, 2);
        list.push({
            "type":this.tType,
            "amount":amount
        });
        if (this.tType == 'Increase') {
            this.tSubTotal += amount;
            this.tNewBalance += amount;
        } else if (this.tType == 'Decrease') {
            this.tSubTotal -= amount;
            this.tNewBalance -= amount;
        }

    }

    deleteTransaction(item:any, list = this.transaction) {
        for(let i=0; i<list.length; i++){
            if (list[i] == item) {
                list.splice(i,1);
                if (item.type == 'Increase') {
                    this.tSubTotal -= item.amount;
                    this.tNewBalance -= item.amount;
                } else if (item.type == 'Decrease') {
                    this.tSubTotal += item.amount;
                    this.tNewBalance += item.amount;
                }
            }
        }
    }

    cancelTransaction() {
        this.state = 'list';
    }

    numberToDecimal(number:number) {
        number /= Math.pow(10, 2);
        return number.toFixed(2);
    }

}
