import { Component, OnInit } from '@angular/core';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-deduction',
  templateUrl: './deduction.component.html',
  styleUrls: ['./deduction.component.sass']
})
export class DeductionComponent implements OnInit {

        disabled:boolean = false;
        dAmount:number = 0;
        dComment:string = '';
        report:any;
        message:string = '';

        constructor(private httpService: HTTPService) { }

        ngOnInit(): void {
        }

        run() {
            this.report = null;
            this.disabled = true;
            var obj = {
                "ammount": (this.dAmount * Math.pow(10, 2)),
                "comment": this.dComment
            };
            this.httpService.sendPostRequest('/deduction/run', obj)
                .subscribe(data=> this.onRunReturn(data));
        }

        onRunReturn(data:any) {
            if (data.message && data.message == 'successful') {
                this.disabled = false;
                this.report = data.data;
                //this.viewTransactions();
            } else if (data.message && data.message == 'failed') {
                this.disabled = false;
                this.message = data.error[0];
            }
        }

        numberToDecimal(number:number = 0) {
            number /= Math.pow(10, 2);
            return number.toFixed(2);
        }

}
