import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
    isAuthenticated: boolean = false;
    currentCopyRightYear: string = "";
    animal: string = "";
    name: string = "";

    constructor(public authService: AuthService) {
        this.authService.isAuthenticated.subscribe(
            (isAuthenticated: boolean) => {this.isAuthenticated = isAuthenticated;}
        );
    }

    async ngOnInit() {
        var date = new Date();
        this.currentCopyRightYear = date.getFullYear().toString();
        await this.authService.checkAuthenticated();
        //this.isAuthenticated = await this.authService.checkAuthenticated();
    }

    logout() {
        this.authService.logout('/');
    }
}
