<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && run()">
    <div class="card">
        <div class="row card-body">
            <div class="col">
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Amount</label>
                    </div>
                    <div class="col-9">
                        <input [disabled]="disabled" class="form-control" id="dAmount" name="dAmount" type="number" min="0.01" [(ngModel)]="dAmount" required/>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Comment</label>
                    </div>
                    <div class="col-9">
                        <input [disabled]="disabled" class="form-control" id="dComment" name="dComment" type="text" [(ngModel)]="dComment" required/>
                    </div>
                </div>
            </div>
            <div class="col">
                <button *ngIf="report == undefined || report == null" class="btn btn-primary" [disabled]="disabled || !f.form.valid" type="submit">Run</button>
            </div>
        </div>
    </div>
</form>
<div class="row pt-3" id="submitMessage">
    <div class=" text-center mb-3 fw-bold">{{message}}</div>
</div>
<div class="card" *ngIf="report !== undefined && report !== null">
    <div class="card-body">
        <table class="table table-bordered " [class.table-hover]="!disabled">
            <thead>
                <tr>
                    <th>Total Deductions</th>
                    <th>Negative Deductions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{'$'+numberToDecimal(report['total'])}}</td>
                    <td>{{'$'+numberToDecimal(report['negative'])}}</td>
                </tr>
            </tbody>
        </table>

    </div>
</div>
