import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass']
})
export class UserComponent implements OnInit {
    state:string = "list";
    page:number = 1;
    pages:number = 1;
    disabled:boolean = true;
    listInfo:any[] = [];
    selected:any;
    editSelected:any;
    itemsLength:number = 10;
    message:string = '';
    searchVal:string = '';

    constructor(
        public authService: AuthService,
        private httpService: HTTPService
    ) {
    }

    ngOnInit(): void {
        this.list();
    }

    list(page = 1) {
        this.disabled = true;
        this.page = page;
        var obj = {
            "search" : this.searchVal
        }
        this.httpService.sendPostRequest('/user/search/'+this.page+'/'+this.itemsLength, obj)
            .subscribe(data=> this.onReturn(data));
    }

    update() {
        this.disabled = true;
        this.httpService.sendPostRequest('/user/update/special', this.editSelected)
            .subscribe(data=> this.onUpdateReturn(data));
    }

    create() {
        this.disabled = true;
        this.httpService.sendPostRequest('/user/create/special', this.editSelected)
            .subscribe(data=> this.onCreateReturn(data));
    }

    onCreateReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.selected = data.data;
            for(let i=0; i< this.listInfo.length; i++){
                if (this.listInfo[i].id == this.selected.id) {
                    this.listInfo[i] = data.data;
                }
            }
            this.select(data.data);
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    onUpdateReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.selected = data.data;
            for(let i=0; i< this.listInfo.length; i++){
                if (this.listInfo[i].id == this.selected.id) {
                    this.listInfo[i] = data.data;
                }
            }
            this.select(data.data);
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    onReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.pages = data.pages;
            this.listInfo = data.data;
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    updatePassword() {
        this.disabled = true;
        this.httpService.sendPostRequest('/user/update/word', this.editSelected)
            .subscribe(data=> this.onPasswordReturn(data));
    }

    onPasswordReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.select(data.data);
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    select(item:any = this.selected) {
        this.selected = item;
        this.state = "view";
    }

    back() {
        this.state="list";
    }

    edit() {
        this.editSelected = (JSON.parse(JSON.stringify(this.selected)));
        this.editSelected.newPassword = '';
        this.state = "edit";
    }

    new() {
        this.editSelected = {};
        this.editSelected.roles=[];
        this.state="create";
    }

    checked(role:string, selected = this.selected) {
        return selected.roles.includes(role);
    }



    checkValue(event:any, permission:string){
        if (event.currentTarget.checked && !this.editSelected.roles.includes(permission))
        {
            this.editSelected.roles.push(permission);
        } else if ( !event.currentTarget.checked && this.editSelected.roles.includes(permission))
        {
            this.editSelected.roles.forEach( (item:string, index:number) => {
                if(item === permission) this.editSelected.roles.splice(index,1);
            });
        }
        console.log(this.editSelected.roles);

    }

}
