import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HTTPService } from '../services/http.service';
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.sass']
})
export class MemberComponent implements OnInit {
    state:string = "list";
    page:number = 1;
    pages:number = 1;
    disabled:boolean = true;
    listInfo:any[] = [];
    listBranch:any[] = [];
    selected:any;
    editSelected:any;
    itemsLength:number = 10;
    message:string = '';
    searchVal:string = '';

    file:any;
    fileName:string = "";

    bName:string = "";
    bRelationship:string = "";
    bAddress:string = "";

    fileToUpload: File|null = null;


    constructor(
        public authService: AuthService,
        private httpService: HTTPService,
        private sanitizer: DomSanitizer
    ) {
    }

    imgURL(url:any) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    onFileChange(files: any) {
        if(files.files) {
            this.fileToUpload = files.files.item(0);
        }
    }

    onSubmit() {
    }

    addImage()
    {
        this.disabled = true;
        this.httpService.sendPostImage('/image/upload/'+this.fileName+'/'+this.editSelected.id, this.fileToUpload)
            .subscribe(data=> this.onImageUpload(data));
    }

    onImageUpload(data:any) {
        if (data.message && data.message == 'successful') {
            this.editSelected.image.push(data.data);
            //this.selected.image.push(data.data);
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    ngOnInit(): void {
        this.list();
        this.branch();
    }

    list(page = 1) {
        this.disabled = true;
        this.page = page;
        var obj = {
            "search" : this.searchVal
        }
        this.httpService.sendPostRequest('/insured/search/'+page+'/'+this.itemsLength, obj)
            .subscribe(data=> this.onReturn(data));
    }

    branch() {
        this.disabled = true;
        this.httpService.sendPostRequest('/branch/all')
            .subscribe(data=> this.onBranchReturn(data));
    }

    onBranchReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.listBranch = data.data;
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }


    update() {
        this.disabled = true;
        this.httpService.sendPostRequest('/insured/update/special', this.editSelected)
            .subscribe(data=> this.onUpdateReturn(data));
    }

    create() {
        var obj = Object.assign({}, this.editSelected);
        this.disabled = true;
        if (obj.branch['id'] == 1) {
            obj.book_number = '48'+ obj.book_number;
        } else if (obj.branch['id'] == 2) {
            obj.book_number = '28'+ obj.book_number;
        }
        this.httpService.sendPostRequest('/insured/create/special', obj)
            .subscribe(data=> this.onCreateReturn(data));
    }

    onCreateReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.select(data.data);
            this.editSelected = null;

        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    onUpdateReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.selected = data.data;
            for(let i=0; i< this.listInfo.length; i++){
                if (this.listInfo[i].id == this.selected.id) {
                    this.listInfo[i] = data.data;
                }
            }
            this.select(data.data);
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    onReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.pages = data.pages;
            this.listInfo = data.data;
            this.disabled = false;
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    select(item:any = this.selected) {
        this.selected = item;
        this.selected.dob = this.dateToString(this.selected.dob);
        if (this.selected.dateClosed) {
            this.selected.dateClosed = this.dateToString(this.selected.dateClosed);
        }
        this.selected.date_of_application = this.dateToString(this.selected.date_of_application);
        this.state = "view";
    }

    back() {
        this.state="list";
    }

    edit() {
        //this.editSelected = Object.assign({}, this.selected);
        this.editSelected = (JSON.parse(JSON.stringify(this.selected)));
        this.state = "edit";
    }

    new() {
        this.editSelected = {beneficiary : [], image : [], branch : {}};
        this.state="create";
    }

    addBeneficiary(list = this.editSelected) {
        list.beneficiary.push({
            "name":this.bName,
            "relationship":this.bRelationship,
            "address":this.bAddress
        });
    }

    deleteBeneficiary(item:any, list = this.editSelected) {
        for(let i=0; i<list.beneficiary.length; i++){
            if (list.beneficiary[i] == item) {
                list.beneficiary.splice(i,1);
            }
        }
    }

    displayDate(date:string) {
        return date.substring(5,7)+'/'+date.substring(8,10)+'/'+date.substring(0,4);
    }

    dateToString(date:string) {
        if (date) {
            return date.substring(0, 10);
        } else {
            return "";
        }

        //return date.substring(5,7)+'/'+date.substring(8,10)+'/'+date.substring(0,4);
        //console.log(date.substring(0, 10));

        //var newDate=new Date(date.substring(0, 10));
        //return "sd";
        //return this.datepipe.transform(newDate, 'yyyy-MM-dd');
    }

    loadImage(item:any) {
        if(!item['image']) {
            this.httpService.getPostImage('/image/file/'+item['name'])
                .subscribe(data=> this.onImageReturn(data, item));
        }
    }

    onImageReturn(data:any, item:any) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            item['image'] = reader.result;
        }, false);

        if (data) {
            reader.readAsDataURL(data);
        }
    }
}
