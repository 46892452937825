import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HTTPService {

    private server =  environment.apiUrl //"https://hancock.blpfsociety.org"// http://blpfsociety.org:8000 // http://hancock.blpfsociety.org ;

    constructor(private http: HttpClient) { }

    public sendPostRequest(action:string, data:object = {}){
        let header = new HttpHeaders();
		header = header.set('x-Auth-Token', sessionStorage.getItem("token")!);
        return this.http.post<any>(this.server+action, data, {headers: header}).pipe(catchError(this.handleError));
    }

    public sendAuthPostRequest(action:string, data:object = {}){
        return this.http.post<any>(this.server+action, data).pipe(catchError(this.handleError));
    }

    public getPostImage(action: string): Observable<Blob> {
        let header = new HttpHeaders();
        header = header.set('x-Auth-Token', sessionStorage.getItem("token")!);
        return this.http.post(this.server+action, { responseType: 'blob' }, {headers: header, responseType: 'blob'}).pipe(catchError(this.handleError));
    }

    public sendPostImage(action: string, fileToUpload: File|null){
        const formData: FormData = new FormData();
        if(fileToUpload) {
            formData.append('fileKey', fileToUpload, fileToUpload.name);
        }
        let header = new HttpHeaders();
        header = header.set('x-Auth-Token', sessionStorage.getItem("token")!);
        return this.http.post(this.server+action, formData, {headers: header}).pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
