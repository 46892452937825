import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { UserComponent } from './user/user.component';
import { MemberComponent } from './member/member.component';
import { TransactionComponent } from './transaction/transaction.component';
import { UiDatePickerDirective } from './components/ui-date-picker.directive';
import { SafePipe } from './components/safe-pipe';
import { MultiTransactionComponent } from './multi-transaction/multi-transaction.component';
import { ReportsComponent } from './reports/reports.component';
import { DeductionComponent } from './deduction/deduction.component';
import { BatchComponent } from './batch/batch.component';
import { BenefitComponent } from './benefit/benefit.component';
import { BatchPersonalComponent } from './batch-personal/batch-personal.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { SettingComponent } from './setting/setting.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    UserComponent,
    MemberComponent,
    TransactionComponent,
    UiDatePickerDirective,
    SafePipe,
    MultiTransactionComponent,
    ReportsComponent,
    DeductionComponent,
    BatchComponent,
    BenefitComponent,
    BatchPersonalComponent,
    AdjustmentComponent,
    SettingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
