<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <legend>Batch List</legend>
    <!-- <div class="input-group mb-3">
        <input [disabled]="disabled" type="text" class="form-control form-control" placeholder="Search by email or user name" name="search" [(ngModel)]="searchVal">
        <button [disabled]="disabled" type="button" class="input-group-text btn-primary" (click)="list()"><i class="fa-solid fa-magnifying-glass"></i></button>
    </div> -->
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>User Name</th>
                <th>Batch ID</th>
                <th>Date Created</th>
                <th>Date Closed</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="listInfo">
                <tr (dblclick)="select(item)" (click)="select(item)" role="button" class="link">
                    <td>{{item['idUser']['userName']}}</td>
                    <td>{{item['id']}}</td>
                    <td>{{dateToString(item['dateCreated'])}}</td>
                    <td>{{dateToString(item['dateClosed'])}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(1)">First</a></li>
        <li class="page-item" [class.disabled]="disabled || page == 1"><a role="button" class="page-link" (click)="list(page-1)">Previous</a></li>
        <li class="page-item" [class.disabled]="disabled"><div class="col-xs-2"><input [disabled]="disabled" (keyup.enter)="list(page)" (focusout)="list(page)" min="1" max="{{pages}}" class="page-link" type="number" name="pagenum" [(ngModel)]="page"></div></li>
        <li class="page-item disabled"><span class="page-link"> of {{pages}}</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(page+1)">Next</a></li>
        <li class="page-item" [class.disabled]="disabled || page == pages"><a role="button" class="page-link" (click)="list(pages)">Last</a></li>
        <li class="page-item disabled"><span class="page-link">Items</span></li>
        <li class="page-item" [class.disabled]="disabled || page == pages">
            <select [disabled]="disabled" class="form-select" [(ngModel)]="itemsLength" (change)="list()">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    </ul>
    <button [disabled]="disabled" class="btn btn-primary" type="button" (click)="new()">Create New</button>
</div>
<div class="" *ngIf="state == 'view'">
    <legend>{{selected['idUser']['userName'] }} - Batch ID : {{selected['id']}}</legend>
    <div class="p-3">
        <span *ngIf="selected['dateClosed']">Closing Total</span><span *ngIf="!selected['dateClosed']">Current Total</span> : {{'$'+numberToDecimal(totalBatch((selected['transaction']? selected['transaction'] : [])))}} 
        <div *ngIf="selected['dateClosed']">
            Closeed on : {{dateToString(selected['dateClosed'])}}
        </div>
    </div>
    <table class="table table-bordered " [class.table-hover]="!disabled">
        <thead>
            <tr>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Book Number</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-item [ngForOf]="selected['transaction']">
                <tr>
                    <td>{{item['type']}}</td>
                    <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                    <td>{{dateToString(item['dateCreated'])}}</td>
                    <td>{{item['insured']['book_number']}}</td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary pr-1" (click)="back()">Back</button>
        <button *ngIf="!selected['dateClosed']" [disabled]="disabled" type="button" class="btn btn-primary pr-1" (click)="closeBatch()">Close Batch</button>
    </div>
</div>
<form id="userForm" name="form" #f="ngForm" class="container" *ngIf="state == 'create'" (ngSubmit)="f.form.valid && create()">
    <legend>Open Batch</legend>
    <div class="row mb-3">
        <div class="col-3">
            <label class="form-label">User</label>
        </div>
        <div class="col-9">
            <select [disabled]="disabled" name="batchUser" #batchUser="ngModel" class="form-select form-control" [(ngModel)]="editSelected" required>
                <ng-template ngFor let-item [ngForOf]="listUser">
                    <option [ngValue]="item">{{item['userName']}}</option>
                </ng-template>
            </select>
            <div *ngIf="batchUser.invalid && (batchUser.dirty || batchUser.touched)" class="alert alert-danger m-0" role="alert">
                <div *ngIf="batchUser.errors?.['required']">
                    Field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="d-flex gap-3">
        <button [disabled]="disabled" class="btn btn-secondary" type="button" (click)="back()">Cancel</button>
        <button [disabled]="disabled || !f.form.valid" class="btn btn-primary" type="submit">Submit</button>
    </div>
</form>
