import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    public email: string = "";
    public password: string = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private httpService: HTTPService
    ) {
    }

    async ngOnInit() {
    }

    async onSubmit() {
        var obj = { "email":this.email, "password":this.password};
        this.httpService.sendAuthPostRequest('/authenticate/call', obj)
            .subscribe(data=> this.onReturn(data));
    }
    onReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.authService.login(data.token);
        } else {
            if (data.message) {
              this.authService.message = data.message;
            }
        }
    }
}
