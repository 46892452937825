import { Component, OnInit } from '@angular/core';
import { HTTPService } from '../services/http.service';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.sass']
})
export class BatchComponent implements OnInit {
    state:string = "list";
    page:number = 1;
    pages:number = 1;
    disabled:boolean = true;
    listInfo:any[] = [];
    selected:any;
    editSelected:any;
    itemsLength:number = 10;
    message:string = '';
    searchVal:string = '';
    listUser:any[] = [];

  constructor(private httpService: HTTPService) { }

  ngOnInit(): void {
      this.list();
      this.users();
  }

  users() {
      this.disabled = true;
      this.httpService.sendPostRequest('/user/all')
          .subscribe(data=> this.onUserReturn(data));
  }

  onUserReturn(data:any) {
      if (data.message && data.message == 'successful') {
          this.listUser = data.data;
          this.disabled = false;
      } else if (data.message && data.message == 'failed') {
          this.disabled = false;
          this.message = data.error[0];
      }
  }

  list(page = 1) {
      this.disabled = true;
      this.page = page;
      this.httpService.sendPostRequest('/batch/list/'+this.page+'/'+this.itemsLength+'/id/desc')
          .subscribe(data=> this.onReturn(data));
  }

  closeBatch() {
      this.disabled = true;
      this.selected['dateClosed'] = new Date().toUTCString();
      this.httpService.sendPostRequest('/batch/close', this.selected)
          .subscribe(data=> this.onUpdateReturn(data));
  }

  create() {
      this.disabled = true;
      this.httpService.sendPostRequest('/batch/open', this.editSelected)
          .subscribe(data=> this.onCreateReturn(data));
  }

  onCreateReturn(data:any) {
      if (data.message && data.message == 'successful') {
          this.disabled = false;
          this.listInfo.unshift(data.data);
          this.state = 'list';
      } else if (data.message && data.message == 'failed') {
          this.disabled = false;
          this.message = data.error[0];
      }
  }

  onUpdateReturn(data:any) {
      if (data.message && data.message == 'successful') {
          this.disabled = false;
          this.selected = data.data;
          //update the user in the list
          for(let i=0; i< this.listInfo.length; i++){
              if (this.listInfo[i].id == this.selected.id) {
                  this.listInfo[i] = data.data;
              }
          }
          this.back();
      } else if (data.message && data.message == 'failed') {
          this.disabled = false;
          this.message = data.error[0];
      }
  }

  onReturn(data:any) {
      if (data.message && data.message == 'successful') {
          this.pages = data.pages;
          this.listInfo = data.data;
          this.disabled = false;
      } else if (data.message && data.message == 'failed') {
          this.disabled = false;
          this.message = data.error[0];
      }
  }

  select(item:any = this.selected) {
      this.selected = item;
      this.state = "view";
  }

  back() {
      this.state="list";
  }

  edit() {
      this.editSelected = Object.assign({}, this.selected);
      this.state = "edit";
  }

  new() {
      this.editSelected = {};
      this.state="create";
  }

  checked(role:string, selected = this.selected) {
      return selected.roles.includes(role);
  }



  checkValue(event:any, permission:string){
      if (event.currentTarget.checked && !this.editSelected.roles.includes(permission))
      {
          this.editSelected.roles.push(permission);
      } else if ( !event.currentTarget.checked && this.editSelected.roles.includes(permission))
      {
          this.editSelected.roles.forEach( (item:string, index:number) => {
              if(item === permission) this.editSelected.roles.splice(index,1);
          });
      }
      console.log(this.editSelected.roles);

  }

  dateToString(date:string) {
      if (date) {
          return date.substring(0, 10)+' '+date.substring(11, 19);
      }
      return null;
      //return date.substring(5,7)+'/'+date.substring(8,10)+'/'+date.substring(0,4);
      //console.log(date.substring(0, 10));

      //var newDate=new Date(date.substring(0, 10));
      //return "sd";
      //return this.datepipe.transform(newDate, 'yyyy-MM-dd');
  }

  numberToDecimal(number:number) {
      number /= Math.pow(10, 2);
      return number.toFixed(2);
  }

  totalBatch(transaction:[]) {
      var total = 0;
      for(let i=0; i< transaction.length; i++){
          if (transaction[i]['type'] == 'Deposit') {
              total += transaction[i]['amount'];
          } else if (transaction[i]['type'] == 'Withdraw') {
              total -= transaction[i]['amount'];
          }

      }
      return total;
  }

}
