<div *ngIf="disabled">
    Loading......
</div>
<div *ngIf="!disabled">
     &nbsp;
</div>
<div class="" *ngIf="state == 'list'">
    <div class="d-grid gap-3">
    <button type="button" class="btn btn-primary btn-block" (click)="show()">Run Report</button>
    <button type="button" class="btn btn-primary btn-block" (click)="members()">Download Member List</button>
  </div>
</div>
<div class="" *ngIf="state == 'report'">
    <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && run()">
        <div class="card">
            <div class="row card-body">
                <div class="col">
                    <div class="row">
                        <div class="col-3">
                            <label class="form-label">Start Date</label>
                        </div>
                        <div class="col-9">
                            <input [disabled]="disabled" class="form-control" uiDatePicker id="rStartDate" name="rStartDate" type="text" [(ngModel)]="rStartDate" required/>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-3">
                            <label class="form-label">End Date</label>
                        </div>
                        <div class="col-9">
                            <input [disabled]="disabled" class="form-control" uiDatePicker id="rEndDate" name="rEndDate" type="text" [(ngModel)]="rEndDate" required/>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <button class="btn btn-primary" [disabled]="disabled || !f.form.valid" type="submit">Run</button>
                </div>
                <div class="col">
                    <button class="btn btn-primary" [disabled]="disabled || !f.form.valid" type="button" (click)="download()">Download</button>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex pt-3">
        <button [disabled]="disabled" type="button" class="btn btn-secondary" (click)="back()">Back</button>
    </div>
    <div class="row pt-3" id="submitMessage">
        <div class=" text-center mb-3 fw-bold">{{message}}</div>
    </div>
    <div class="card mb-3" *ngIf="report !== undefined && report !== null">
        <div class="card-body">
            <table class="table table-bordered " [class.table-hover]="!disabled">
                <thead>
                    <tr>
                        <th>Total Intake (Deposit)</th>
                        <th>Total Withdrawals (Withdraw)</th>
                        <th>Total Member Deductions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >{{'$'+numberToDecimal(report['Deposit'])}}</td>
                        <td>{{'$'+numberToDecimal(report['Withdraw'])}}</td>
                        <td>{{'$'+numberToDecimal(report['Deduct'])}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card" *ngIf="report !== undefined && report !== null">
        <div class="input-group my-3">
            <select [disabled]="disabled" name="batchUser" #batchUser="ngModel" class="form-select form-control mx-2" (change)="filter()" [(ngModel)]="editInsure" required>
                <ng-template ngFor let-item [ngForOf]="insuredList">
                    <option [ngValue]="item['book_number']">{{item['book_number']+' | '+item['fname']+' '+item['lname']}}</option>
                </ng-template>
            </select>
            <button [disabled]="disabled" type="button" class="btn btn-primary" (click)="clear()">Clear</button>
        </div>
        <table class="table table-bordered " [class.table-hover]="!disabled">
            <thead>
                <tr>
                    <th>Book Number</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Transaction</th>
                    <th>Amount</th>
                    <th>Date Created</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="fullReport">
                    <tr role="button">
                        <td>{{item['book_number']}}</td>
                        <td>{{item['fname']}}</td>
                        <td>{{item['lname']}}</td>
                        <td>{{item['type']}}</td>
                        <td>{{'$'+numberToDecimal(item['amount'])}}</td>
                        <td>{{dateToString(item['date_created'])}}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>
