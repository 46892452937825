import { Component, OnInit } from '@angular/core';
import { HTTPService } from '../services/http.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit {
    state:string = "list";
    disabled:boolean = false;
    rStartDate:string = '';
    rEndDate:string = '';
    report:any;
    reportOriginal:any;
    message:string = '';
    fullReport:any;
    editInsure:string = '';
    insuredList:any[] = [];

    constructor(private httpService: HTTPService) { }

    ngOnInit(): void {
    }

    back() {
        this.state="list";
    }

    show() {
        this.state="report";
    }


    run() {
        this.report = null;
        this.fullReport = null;
        this.reportOriginal=null;
        this.disabled = true;
        this.message = '';
        var obj = {
            "startDate": this.rStartDate,
            "endDate": this.rEndDate
        };
        this.httpService.sendPostRequest('/report/run', obj)
            .subscribe(data=> this.onRunReturn(data));
    }

    onRunReturn(data:any) {
        if (data.message && data.message == 'successful' && (data.data === undefined || data.data.length == 0)) {
            this.disabled = false;
            this.message = "Report is empty.";
        } else if (data.message && data.message == 'successful') {
            this.disabled = false;
            this.report = data.data[0];
            this.fullReport = data.data2;
            this.reportOriginal = data.data2;
            this.insured(data.data2);
            //this.viewTransactions();
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    members(){
        this.report = null;
        this.fullReport = null;
        this.reportOriginal=null;
        this.disabled = true;
        this.message = '';
        this.httpService.sendPostRequest('/report/download/members')
            .subscribe(data=> this.onMembersReturn(data));
    }

    onMembersReturn(data:any) {
        if (data.message && data.message == 'successful') {
            this.disabled = false;
            for(let i=0; i< data.data.length; i++){
                data.data[i]['balance'] = '$'+this.numberToDecimal(data.data[i]['balance']);
            }
            const replacer = (key:any, value:any) => value === null ? '' : value; // specify how you want to handle null values here
            const header = Object.keys(data.data[0]);
            let csv = data.data.map((row:any)=> header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');

            var blob = new Blob([csvArray], {type: 'text/csv' });
            let yourDate = new Date();
            let now = yourDate.toISOString().split('T')[0];
            saveAs(blob, "Members_on_"+now+".csv");
            //this.viewTransactions();
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    download() {
        this.disabled = true;
        this.message = '';
        var obj = {
            "startDate": this.rStartDate,
            "endDate": this.rEndDate
        };
        this.httpService.sendPostRequest('/report/download', obj)
            .subscribe(data=> this.onDownloadReturn(data));
    }

    onDownloadReturn(data:any) {
        if (data.message && data.message == 'successful' && (data.data === undefined || data.data.length == 0)) {
            this.disabled = false;
            this.message = "Report is empty.";
        } else if (data.message && data.message == 'successful') {
            this.disabled = false;
            for(let i=0; i< data.data.length; i++){
                data.data[i]['amount'] = '$'+this.numberToDecimal(data.data[i]['amount']);
            }
            const replacer = (key:any, value:any) => value === null ? '' : value; // specify how you want to handle null values here
            const header = Object.keys(data.data[0]);
            let csv = data.data.map((row:any)=> header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');

            var blob = new Blob([csvArray], {type: 'text/csv' });
            let yourDate = new Date();
            let now = yourDate.toISOString().split('T')[0];
            saveAs(blob, "Report_"+this.dateToString(this.rStartDate)+"_to_"+this.dateToString(this.rEndDate)+'_on_'+now+".csv");
            //this.viewTransactions();
        } else if (data.message && data.message == 'failed') {
            this.disabled = false;
            this.message = data.error[0];
        }
    }

    numberToDecimal(number:number = 0) {
        number /= Math.pow(10, 2);
        return number.toFixed(2);
    }

    containsObject(obj:any, list:any[]) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].book_number === obj.book_number) {
                return true;
            }
        }

        return false;
    }

    insured(list:any[]) {
        this.insuredList = [];
        for(let i=0; i< list.length; i++){
            if (!this.containsObject({book_number : list[i].book_number}, this.insuredList)) {
                this.insuredList.push(
                    {
                        book_number : list[i].book_number,
                        fname : list[i].fname,
                        lname : list[i].lname
                    }
                );
            }
        }
    }

    filter() {
        var tempList = [];
        if (this.editInsure){
            var i;
            for (i = 0; i < this.reportOriginal.length; i++) {
                if (this.reportOriginal[i].book_number === this.editInsure) {
                    tempList.push(this.reportOriginal[i]);
                }
            }
            this.fullReport = tempList;
        } else {
            this.fullReport = this.reportOriginal;
        }

    }

    clear() {
        this.editInsure = '';
        this.fullReport = this.reportOriginal;
    }

    dateToString(date:string) {
        return date.substring(0, 10);
    }

}
