import { Component, OnInit } from '@angular/core';
import { HTTPService } from '../services/http.service';


@Component({
  selector: 'app-batch-personal',
  templateUrl: './batch-personal.component.html',
  styleUrls: ['./batch-personal.component.sass']
})
export class BatchPersonalComponent implements OnInit {
    state:string = "none";
    disabled:boolean = true;
    listInfo:any[] = [];
    selected:any;
    editSelected:any;
    itemsLength:number = 10;
    message:string = '';
    listUser:any[] = [];

  constructor(private httpService: HTTPService) { }

  ngOnInit(): void {
      this.list();
  }

  refresh()
  {
      this.list();
  }

  list() {
      this.disabled = true;
      this.httpService.sendPostRequest('/batch/personal')
          .subscribe(data=> this.onReturn(data));
  }

  onReturn(data:any) {
      if (data.message && data.message == 'successful') {
          this.selected = data.data[0];
          this.disabled = false;
          this.state = "view";
      } else if (data.message && data.message == 'failed') {
          this.state = "error";
          this.disabled = false;
          this.message = data.error[0];
      }
  }

  dateToString(date:string) {
      if (date) {
          return date.substring(0, 10)+' '+date.substring(11, 19);
      }
      return null;
  }

  numberToDecimal(number:number) {
      number /= Math.pow(10, 2);
      return number.toFixed(2);
  }

  totalBatch(transaction:[]) {
      var total = 0;
      for(let i=0; i< transaction.length; i++){
          if (transaction[i]['type'] == 'Deposit') {
              total += transaction[i]['amount'];
          } else if (transaction[i]['type'] == 'Withdraw') {
              total -= transaction[i]['amount'];
          }

      }
      return total;
  }

}
